import React, { Component } from 'react';

import { connect } from 'react-redux';
import { sourceAuthList, sourceList, sourceCreateOrUpdate } from 'lib/Api';
import * as model from 'Models';
import {
  Button,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  ListGroup
} from 'reactstrap';

import moment from 'moment';
import SourceListNew from './SourceListNew';

interface IgAccount {
  id: string;
  name: string;
  username: string;
}

interface FbAccountItem {
  access_token: string;
  id: string;
  name: string;
  igAccount: IgAccount | null | undefined;
}

interface FbUser {
  id: string;
  name: string;
  email: string;
  expiresAt: Date;
}
interface IProps {
  //selectCustomer: typeof selectCustomer,
}

interface IState {
  items?: model.Source[];
  isLoading: boolean;
  fbUser: FbUser | null | undefined;
  accounts: FbAccountItem[] | null;
  sourceAuthList: model.SourceAuthViewModel[] | null;
}

class SourceList extends Component<IProps, IState> {
  state: IState = {
    items: [],
    isLoading: true,
    accounts: null,
    fbUser: null,
    sourceAuthList: null
  };

  async componentDidMount() {
    const saList = await sourceAuthList();
    const items = await sourceList();
    this.setState({ sourceAuthList: saList, items: items });
  }

  toggleEnabled = async (x: model.Source) => {
    var clone = { ...x, active: !x.active };
    await sourceCreateOrUpdate(clone);
    const items = await sourceList();
    this.setState({ items: items });
  };

  renderSourceItem = (item: model.Source) => {
    return (
      <ListGroupItem key={item.id}>
        <ListGroupItemHeading>
          <a
            href={`https://www.instagram.com/explore/tags/${item.hashtag}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Open in new window"
          >
            #{item.hashtag}
          </a>
        </ListGroupItemHeading>
        <ListGroupItemText>
          Last polled: {item.lastPolled && moment(item.lastPolled).fromNow()}{' '}
          <br />
          Enabled: {item.active ? 'Yes' : 'No'} <br />
          {/* <ActionLink onClick={() => { this.toggleEnabled(item) }}>{item.active ? "Disable" : "Enable"}</ActionLink> */}
          <Button
            size="sm"
            onClick={() => {
              this.toggleEnabled(item);
            }}
          >
            {item.active ? 'Disable' : 'Enable'}
          </Button>
          {/* Connected by {item.} ({item.userEmail})<br />
                    <small>Expires {item.expiresAt && moment(item.expiresAt).fromNow()}</small> */}
        </ListGroupItemText>
      </ListGroupItem>
    );
  };

  onSourceAuthSubmit = async (item: model.Source) => {
    const items = await sourceList();
    this.setState({ items: items });
  };

  render() {
    const arr = this.state.items;
    let emptySource: model.Source = {
      id: 0,
      sourceAuthId: 0,
      type: 0,
      hashtag: '',
      active: false,
      lastPolled: null
    };
    if (this.state.sourceAuthList && this.state.sourceAuthList.length > 0) {
      emptySource.sourceAuthId = this.state.sourceAuthList[0].id;
    }
    return (
      <div>
        <p>
          You can have at most 30 hashtags per account. This limit is enforced
          per rolling 7 day period.
        </p>
        <div className="row mt-4">
          <div className="col-lg-6">
            <SourceListNew
              sourceAuthList={this.state.sourceAuthList}
              value={emptySource}
              onSubmit={this.onSourceAuthSubmit}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6">
            <h3>Sources</h3>
            <ListGroup>{arr && arr.map(this.renderSourceItem)}</ListGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(SourceList);
