import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import './Layout.css';

export default (props: any) => (
  <div>
    <NavMenu />
    <Container fluid={true}>{props.children}</Container>
    <footer className="footer">
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span>Copyright © Ugcftw 2019</span>
        </div>
      </div>
    </footer>
  </div>
);
