import { AnyAction } from 'redux';
import { Source as S } from 'Models';

export type Source = S;

export interface SourceState {
  sources?: Source[];
}

export const FETCH_SOURCES_OK = 'FETCH_SOURCES_OK';

interface FetchSourceOkAction {
  type: typeof FETCH_SOURCES_OK;
  payload: Source[];
}

export type SourceActionTypes = FetchSourceOkAction | AnyAction;
