import React, { FunctionComponent } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
  withRouter,
  //  withRouter
} from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "store";

// import { AppState } from "store";
// import { connect } from "react-redux";

// These props are provided when creating the component
interface OwnProps extends RouteProps {
  // ...
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}

// These props are provided via connecting the component to the store
interface StateProps {
  authenticated: boolean | null;
}

// These props are provided by the router
interface PathProps {
}

// From here: https://stackoverflow.com/questions/42309708/create-own-react-route-class-in-typescript
const PrivateRoute: FunctionComponent<OwnProps & StateProps & RouteComponentProps<PathProps>> = ({
  component: Component,
  ...rest
}) => {
  console.log("PrivateRoute outer render() rest.authenticated=", rest.authenticated);

  return (
    <Route
      {...rest}
      render={props => {
        console.log("PrivateRoute inner render() rest.authenticated=", rest.authenticated);
        if (rest.authenticated == null) {
          return <></>;
        }

        return (rest.authenticated)
          ? ( //put your authenticate logic here
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login"
              }}
            />
          )
      }
      }
    />
  );
};

//export default PrivateRoute;

// Har inte lyckats få detta att fungera. När jag använder nedanstående funktion för att hämta ut state ur 
// redux in i komponenten, så renderar den aldrig om sig verkar det som. Den renderar bara rätt data
// om jag råkar stå på komponenten när sidan laddas, men ingenting därefter påverkar den.

// Verkar som jag har fått det att fungera nu. Det svåraste i det här var nog själva redux-integrationen, 
// men eventuellt funkar det nu och jag har inte använt något paket längre för att få in location-statet i redux
// Mer info om själva integrationen här: 
// https://reacttraining.com/react-router/core/guides/redux-integration/deep-integration
function mapStateToProps(state: AppState) {
  return {
    authenticated: state.auth.isAuthenticated,
  }
}

//export default connect(mapStateToProps)(PrivateRoute);

export default withRouter<OwnProps & RouteComponentProps<PathProps>>(connect(mapStateToProps)(PrivateRoute));
