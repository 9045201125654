import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import LoaderButton from './LoaderButton';
import './Login.css';
import { accountRegister } from 'lib/Api';
import { RegistrationResultViewModel } from 'Models';

interface Props {
  userHasAuthenticated?: any;
}

interface IState {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  isLoading: boolean;
  message: string;
  result: RegistrationResultViewModel | null;
}

export default class Login extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    console.log('Login ctor');

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      message: '',
      result: null,
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0 && this.state.firstName.length > 0 && this.state.lastName.length > 0;
  }

  handleChange = (event: any) => {
    if (event.target.id === 'email') {
      this.setState({ email: event.target.value });
    } else if (event.target.id === 'password') {
      this.setState({ password: event.target.value });
    } else if (event.target.id === 'firstName') {
      this.setState({ firstName: event.target.value });
    } else if (event.target.id === 'lastName') {
      this.setState({ lastName: event.target.value });
    }
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let message = "";

    let result = null;

    try {
      result = await accountRegister(this.state);
      if (result.userAlreadyExists) {
        if (result.emailConfirmed) {
          message = "User already exists and confirmed. If you forgot your password, please request a new one." ;
          return;
        }
      }
      message = "A confirmation email has been sent.";
    } catch (e) {
      message = "An error occured, please check the form."
      console.log(e);
    }
    this.setState({ isLoading: false, message: message, result: result });
  };

  render() {
    return (
      <div className="Login">
        <h1>Sign up</h1>
        <form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              id="email"
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              id="password"
            />
          </FormGroup>
          <FormGroup>
            <Label>First name</Label>
            <Input
              autoFocus
              type="text"
              value={this.state.lastName}
              onChange={this.handleChange}
              id="lastName"
            />
          </FormGroup>
          <FormGroup>
            <Label>Last name</Label>
            <Input
              autoFocus
              type="text"
              value={this.state.firstName}
              onChange={this.handleChange}
              id="firstName"
            />
          </FormGroup>
          <LoaderButton
            block
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Sign up"
            loadingText="Loading…"
          />
          {this.state.result && (
            <FormGroup>
              Signed up! Check your email to confirm it before signing in.
          </FormGroup>
          )}
        </form>
      </div>
    );
  }
}
