import queryString from 'query-string';
import * as model from 'Models';

//import { Auth } from 'aws-amplify';
import { Auth } from './Auth';

import { getStore } from 'store';

var API_URL = '/api/';

if (window.location.hostname === 'localhost') {
  //API_URL = 'https://localhost:5001/api/';
  API_URL = 'https://localhost:44323/api/';
}
API_URL = 'https://app.ugcftw.win/api/';

export const ApiUrl = API_URL;

export const fetchData = async (
  endpoint: string,
  queryParams = {},
  init: RequestInit | undefined = {},
  throwOnError = true,
  addDefaultContentType = true,
  apiURL = API_URL
) => {
  const baseInitArgs = {
    headers: {},
    method: 'GET'
  };

  const qsStr = queryString.stringify(queryParams) || '';

  endpoint = `${endpoint}${qsStr && '?'}${qsStr}`;

  // if (!auth && (fakeAuth.authInfo != null)) {
  //   auth = fakeAuth.authInfo;
  // };
  // const session = await Auth.currentSession();
  // console.log('using auth', session);
  const jwtToken = await Auth.getValidJwtToken();

  const customer = getStore().getState().customer.customer;
  console.log('customer', customer);

  Object.assign(
    baseInitArgs.headers,
    addDefaultContentType && { 'Content-type': 'application/json' },
    // session && {
    //   //Authorization: 'Bearer ' + session.getIdToken().getJwtToken()
    // },
    jwtToken && {
      Authorization: 'Bearer ' + jwtToken,
    },
    customer && { customerId: customer.id }
  );

  const finalInit = {
    ...baseInitArgs,
    ...init
  };

  return fetch(apiURL + endpoint, finalInit).then(response => {
    var contentType = response.headers.get('content-type');
    const isJson = contentType && contentType.includes('application/json');

    if (throwOnError && !response.ok) {
      if (isJson) {
        return response.text().then(value => {
          //const retval = deserialize(value);
          const errorJson = Error(value);
          //error.status = response.status;
          throw errorJson;
        });
      }
      var error = Error(response.statusText);
      //error.status = response.status;
      throw error;
    }

    if (isJson) {
      return response.text().then(value => {
        return deserialize(value);
      });
    }
  });
};

function deserialize(data: string): any {
  return JSON.parse(data, dateParser);
}

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

function dateParser(key: any, value: any) {
  if (typeof value === 'string') {
    var a = reISO.exec(value);
    if (a) return new Date(value);
    a = reMsAjax.exec(value);
    if (a) {
      var b = a[1].split(/[-+,.]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]);
    }
  }
  return value;
}

export async function accountRegister(
  vm: model.RegistrationViewModel
): Promise<model.RegistrationResultViewModel> {
  const url = `Account`;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(vm)
  });
  return response;
}

export async function accountConfirm(
  vm: model.RegistrationConfirmEmailViewModel
): Promise<void> {
  const url = `Account/ConfirmEmail`;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(vm)
  });
  return response;
}

export async function accountLogin(
  vm: model.LoginViewModel
): Promise<model.CredentialsResponseViewModel> {
  const url = `auth/login`;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(vm)
  });
  return response;
}

export async function getOrCreateUser(): Promise<model.UserVm> {
  const url = `User/GetOrCreateUser`;
  const response = await fetchData(url, undefined, { method: 'POST' });
  return response;
}

export async function customerList(): Promise<model.CustomerWithPermissions[]> {
  const url = `Customer`;
  const response = await fetchData(url);
  return response;
}

export async function customerCreate(
  customer: model.Customer
): Promise<model.Customer> {
  const url = `Customer`;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(customer)
  });
  return response;
}

export async function findHashTag(
  sourceAuthId: number,
  hashTag: string
): Promise<model.Customer> {
  const url = `Customer`;
  const response = await fetchData(url, { sourceAuthId, hashTag });
  return response;
}

const ControllerSourceAuth = `SourceAuth`;
/// Customer dependant functions below
export async function sourceAuthList(): Promise<model.SourceAuthViewModel[]> {
  const url = ControllerSourceAuth;
  const response = await fetchData(url);
  return response;
}

export async function sourceAuthListCreateOrUpdate(
  value: model.SourceAuthViewModel
): Promise<model.SourceAuthViewModel> {
  const url = ControllerSourceAuth;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(value)
  });
  return response;
}

const ControllerSource = `Source`;
export async function sourceList(): Promise<model.Source[]> {
  const url = ControllerSource;
  const response = await fetchData(url);
  return response;
}
export async function sourceCreateOrUpdate(
  value: model.Source
): Promise<model.Source> {
  const url = ControllerSource;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(value)
  });
  return response;
}

const ControllerFeed = `Feed`;
export async function tagList(): Promise<model.Feed[]> {
  const url = ControllerFeed;
  const response = await fetchData(url);
  return response;
}
export async function tagCreateOrUpdate(
  value: model.Feed
): Promise<model.Feed> {
  const url = ControllerFeed;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(value)
  });
  return response;
}

const ControllerPost = `Post`;
export async function postList(value?: model.PostListRequest): Promise<
  model.PaginationInfoWrapper<model.CustomerPost>
> {
  const url = ControllerPost;
  const response = await fetchData(url, value);
  return response;
}

export async function postCreateOrUpdate(
  value: model.CustomerPost
): Promise<model.CustomerPost> {
  const url = ControllerPost;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(value)
  });
  return response;
}

const ControllerFeedPost = `FeedPost`;
export async function feedPostList(feedId: string): Promise<
  model.PaginationInfoWrapper<model.FeedPostViewModel>
> {
  const url = `${ControllerFeedPost}/${feedId}`;
  const response = await fetchData(url);
  return response;
}

export async function feedPostCreateOrUpdate(feedId: string, items: model.FeedPostViewModel[]): Promise<
  model.FeedPostViewModel[]
> {
  const url = `${ControllerFeedPost}/${feedId}/multiple`;
  const response = await fetchData(url, undefined, {
    method: 'POST',
    body: JSON.stringify(items)
  });
  return response;
}

// export async function getJsonForPartno(siteId: string, feedfileId: number, partNo: string): Promise<string> {
//   const url = `Feed/${siteId}/feedfile/${feedfileId}/partNo/${partNo}/json`;
//   const response = await fetchData(url);
//   return response.text;
// }

// export async function getFullSyncById(siteId: string, fullSyncId: string): Promise<model.FeedFullSync> {
//   const url = `Feed/${siteId}/fullsync/${fullSyncId}`;
//   const response = await fetchData(url);
//   return response;
// }

// export async function getFeedFileById(siteId: string, fileId: string): Promise<model.FeedFile> {
//   const url = `Feed/${siteId}/feedfile/${fileId}`;
//   const response = await fetchData(url);
//   return response;
// }

// export class FakeAuth {
//   authInfo?: AuthInfo = undefined;
//   isAuthenticated: boolean = false;
//   notifyList: { (): void }[] = [];

//   authenticate(authInfo: AuthInfo) {
//     this.isAuthenticated = true;
//     this.authInfo = authInfo;

//     this.notifyList.forEach(element => {
//       element();
//     });
//   }

//   signout() {
//     this.isAuthenticated = false;
//     this.authInfo = undefined;

//     this.notifyList.forEach(element => {
//       element();
//     });
//   }

//   onChanged(cb: () => any) {
//     this.notifyList.push(cb);
//   }
// }

// export const fakeAuth = new FakeAuth();
