import { useState, useCallback, useLayoutEffect, useEffect, SetStateAction } from "react";

export type UseDimensionsHook = [
    //React.Dispatch<SetStateAction<boolean>>
    (x: boolean) => void
];

(window as any).scrollLockEnabledLocal = false;

const setScrollLockEnabled = (x: boolean) => {
    (window as any).scrollLockEnabledLocal = x;
}

function useMyScrollLock(): UseDimensionsHook {
    //const [scrollLockEnabled, setScrollLockEnabled] = useState(false);

    console.log("useMyScrollLock outer in()");
    useEffect(() => {
        const scrollStop = (ev: Event) => {
            console.log("scroll stop ", (window as any).scrollLockEnabledLocal);
            if ((window as any).scrollLockEnabledLocal) {
                ev.preventDefault();
                return false;
            }
            return true;
        }

        console.log("useMyScrollLock in()");
        window.addEventListener("touchmove", scrollStop, { passive: false });
        return () => {
            console.log("Remove event listener useMyScrollLock");
            window.removeEventListener("touchmove", scrollStop);
            //window.removeEventListener("scroll", measure);
        };
    }, []);

    return [setScrollLockEnabled];
}

export default useMyScrollLock;
