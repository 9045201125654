import { LOGOUT, LOGIN, AuthActionTypes } from './types';
import { CredentialsResponseViewModel } from 'Models';

// TypeScript infers that this function is returning SendMessageAction
export function login(data: CredentialsResponseViewModel): AuthActionTypes {
  return {
    type: LOGIN,
    data: data,
  };
}

// TypeScript infers that this function is returning DeleteMessageAction
export function logout(): AuthActionTypes {
  return {
    type: LOGOUT
  };
}
