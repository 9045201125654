import { AnyAction } from 'redux';
import { Feed as S } from 'Models';

export type Feed = S;

export interface FeedState {
  feeds?: Feed[];
  feedById: { [id: string]: Feed; }
}

export const FETCH_FEEDS_OK = 'FETCH_FEEDS_OK';

interface FetchFeedOkAction {
  type: typeof FETCH_FEEDS_OK;
  payload: Feed[];
}

export type FeedActionTypes = FetchFeedOkAction | AnyAction;
