import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router';
import Layout from './components/Layout';
import Playground from './components/Playground';
import Home from './components/Home';
import CustomerList from './components/customer/CustomerList';
import SourceAuthList from './components/sourceauth/SourceAuthList';
import SourceList from './components/source/SourceList';
import FeedList from './components/feed/FeedList';
import PostList from './components/post/PostList';
import FeedPostList from './components/feedpost/PostList';
import FeedPostList2 from './components/feedpost/PostList2';

// import Amplify from 'aws-amplify';
//import awsconfig from './aws-exports';
// import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
// import { Authenticator, SignIn } from "aws-amplify-react";
import { ThemeProvider } from 'styled-components';
import { myTheme } from './theme/theme';

//import { Auth } from 'aws-amplify';
import { Auth } from 'lib/Auth';
import { connect } from 'react-redux';
import { login } from './store/auth/actions';
import { AppState } from './store';
import { selectCustomer } from 'store/customer/actions';
import { CustomerState } from 'store/customer/types';

import FaceBookSdk from './components/FacebookSdk';

// import { CustomSignIn } from 'components/auth/CustomSignIn';

import AuthenticatedRoute from 'components/auth/AuthenticatedRoute';
import UnauthenticatedRoute from 'components/auth/UnauthenticatedRoute';
import Login from 'components/auth/Login';
import Signup from 'components/auth/Signup';
import SignupConfirmation from 'components/auth/SignupConfirmation';

interface AppProps {
  login: typeof login;
  selectCustomer: typeof selectCustomer;
  state: AppState;
  customer: CustomerState;
  authState: any;
}

interface IAppState {
  isAuthenticated: boolean | null;
  isAuthenticating: boolean;
}

class App extends Component<AppProps, IAppState> {
  state = {
    isAuthenticated: null,
    isAuthenticating: true
  };

  async componentDidMount() {
    try {
      const tmp = await Auth.currentAuthenticatedUser();
      if (tmp != null) {
        await this.userHasAuthenticated(true);
      } else {
        await this.userHasAuthenticated(false);
      }
    } catch (e) {
      window.console.log('error', e);
      if (e !== 'not authenticated') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = async (authenticated: boolean) => {
    this.setState({ isAuthenticated: authenticated });

    await this.afterLogin();

    // if (authenticated) {
    //   await this.afterLogin();
    // }
    // else {
    //   this.props.logout();
    // }
  };

  async afterLogin() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      console.log('user', user);

      // const credentials = await Auth.currentCredentials();
      // console.log('using credentials', credentials);

      // const session = await Auth.currentSession();
      // console.log("using auth", session);
      // try {
      // } catch (e) {
      //   Auth.federatedSignIn()
      // }

      await Auth.afterLogin(user);
    } catch (e) {
      console.log("App afterLogin catch", e);
    }
  }

  render() {
    const childProps = {
      isAuthenticated:
        this.props.state.auth && this.props.state.auth.isAuthenticated,
    };

    console.log('app render() isAuthenticated=', childProps.isAuthenticated);

    return (
      <Layout>
        <FaceBookSdk />
        {/* {!childProps.isAuthenticated && (
          <Switch> */}
        <UnauthenticatedRoute
          path="/login"
          exact
          component={Login}
          props={childProps}
        />
        <UnauthenticatedRoute
          path="/signup"
          exact
          component={Signup}
          props={childProps}
        />
        <UnauthenticatedRoute
          path="/signup/confirmation?"
          exact
          component={SignupConfirmation}
          props={childProps}
        />
        {/* <Redirect from="*" to="/login" /> */}
        {/* </Switch>
        )} */}
        <AuthenticatedRoute exact path="/customers" component={CustomerList} />
        {this.props.state.customer.customer && (
          <Fragment>
            <AuthenticatedRoute exact path="/sourceauths" component={SourceAuthList} />
            <AuthenticatedRoute exact path="/sources" component={SourceList} />
            <AuthenticatedRoute exact path="/feeds" component={FeedList} />
            <AuthenticatedRoute exact path="/posts" component={PostList} />
            <AuthenticatedRoute exact path="/feedposts/:id" component={FeedPostList2} />
          </Fragment>
        )}
        <Route exact path="/playground" component={Playground} />

        <AuthenticatedRoute exact path="/" component={Home} />
      </Layout>
    );
  }
}

const ConnectedApp = withRouter(
  connect(
    (state: AppState) => {
      return {
        state: state,
        customerState: state.customer,
      };
    },
    { login, selectCustomer }
  )(App)
);

const AppWithTheme = () => (
  <ThemeProvider theme={myTheme}>
    <ConnectedApp />
  </ThemeProvider>
);

// const oauth = {
//   domain: 'picstream.auth.eu-central-1.amazoncognito.com',
//   scope: ['email', 'profile'],
//   redirectSignIn: 'https://localhost:44323/',
//   redirectSignOut: 'https://localhost:44323/',
//   responseType: 'code'
// };

// Amplify.configure({
//   Auth: {
//     // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//     identityPoolId: 'eu-central-1:25dddc1f-41ec-479b-ae01-d9fb17c88dbe',

//     // REQUIRED - Amazon Cognito Region
//     region: 'eu-central-1',

//     // OPTIONAL - Amazon Cognito Federated Identity Pool Region
//     // Required only if it's different from Amazon Cognito Region
//     //identityPoolRegion: 'XX-XXXX-X',

//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: 'eu-central-1_vWs534qWZ',

//     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//     userPoolWebClientId: '7blth67ahn8philc6hhv3mbtcc',

//     // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
//     mandatorySignIn: false

//     // OPTIONAL - Configuration for cookie storage
//     // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
//     // cookieStorage: {
//     //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
//     //   domain: '.yourdomain.com',
//     //   // OPTIONAL - Cookie path
//     //   path: '/',
//     //   // OPTIONAL - Cookie expiration in days
//     //   expires: 365,
//     //   // OPTIONAL - Cookie secure flag
//     //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
//     //   secure: true
//     // },

//     // OPTIONAL - customized storage object
//     //storage: new MyStorage(),

//     // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//     //authenticationFlowType: 'USER_PASSWORD_AUTH'
//   },
//   oauth: oauth
// });

// const signUpConfig = {
//   hiddenDefaults: ['username', 'phone_number', 'email'],
//   signUpFields: [
//     {
//       label: 'Email',
//       key: 'username', // !!!
//       required: true,
//       displayOrder: 1,
//       type: 'email',
//       custom: false
//     },
//     // {
//     //   label: 'Email',
//     //   key: 'email',
//     //   required: true,
//     //   displayOrder: 1,
//     //   type: 'email',
//     //   custom: false
//     // },
//     {
//       label: 'Password',
//       key: 'password',
//       required: true,
//       displayOrder: 2,
//       type: 'password',
//       custom: false
//     },
//     // {
//     //   label: 'Phone Number',
//     //   key: 'phone_number',
//     //   required: true,
//     //   displayOrder: 3,
//     //   type: 'tel',
//     //   custom: false
//     // }
//     {
//       label: 'Given name',
//       key: 'given_name',
//       required: true,
//       displayOrder: 3,
//       type: 'text',
//       custom: false
//     },
//     {
//       label: 'Family name',
//       key: 'family_name',
//       required: true,
//       displayOrder: 4,
//       type: 'text',
//       custom: false
//     },
//   ]
// };

// Implement refreshtoken functionality for facebook tokens
// function refreshToken() {
//   // refresh the token here and get the new token info
//   // ......

//   return new Promise((res, rej) => {
//       const expires_at = 1000;
//       const token = "asdf";

//       const data = {
//           token, // the token from the provider
//           expires_at, // the timestamp for the expiration
//       }
//       res(data);
//   });
// }
// Auth.configure({
//   refreshHandlers: {
//       'facebook': refreshToken // the property could be 'google', 'facebook', 'amazon', 'developer', OpenId domain
//   }
// })

// const federated = {
//   // google_client_id: '',
//   facebook_app_id: '731070053974064',
//   // amazon_client_id: ''
// };

// with Aws Cognito login
//const AppWithAuthentication = withAuthenticator(AppWithTheme, { signUpConfig, federated: federated } );
// without Aws Cognito login
//export default AppWithTheme;

// class AppWithAuth extends React.Component {
//   constructor(props: any, context: any) {
//     super(props, context);
//   }

//   render() {
//     return (
//       <div>
//         <Authenticator hide={[SignIn]} amplifyConfig={{signUpConfig}}>
//           // @ts-ignore
//           <CustomSignIn />
//           <AppWithTheme />
//         </Authenticator>
//       </div>
//     );
//   }
// }

export default AppWithTheme;
