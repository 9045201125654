import { FeedState, FETCH_FEEDS_OK, FeedActionTypes } from './types';
import { Feed } from 'Models';

const initialState: FeedState = { feeds: undefined, feedById: {} };

export function feedReducer(
  state = initialState,
  action: FeedActionTypes
): FeedState {
  switch (action.type) {
    case FETCH_FEEDS_OK:
      const l = action.payload as Feed[];
      const feedById: { [id: string]: Feed; } = {};
      const dict = l.reduce((output, value) => {
        output[value.id] = value;
        return output;
      }, feedById);
  
      return { ...state, feeds: action.payload, feedById: dict };
    default:
      return state;
  }
}
