import React, { Component } from 'react';

// import { selectCustomer } from 'store/customer/actions';
import { postCreateOrUpdate } from 'lib/Api';
import * as model from 'Models';
import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import CustomSelect from '../forms/CustomSelect';

interface IProps {
  value: model.CustomerPost;
  onSubmit?: (value: model.CustomerPost) => void;
  cancel: () => void;
  getSourceName: (value: model.CustomerPost) => string | null;
  getFeeds: () => model.Feed[];
}

class PostEdit extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  cancel() {
    this.props.cancel();
  }

  render() {
    let initialValues = this.props.value
      ? this.props.value
      : { approved: false };
    console.log("PostEdit render() initialValues=", initialValues);

    let sourceName = this.props.getSourceName(this.props.value);

    let feeds = this.props.getFeeds();

    const selectOptions = feeds
      ? feeds.map(x => ({
          value: x.id,
          label: x.name
        }))
      : [];

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('onsubmit()', values);
          try {
            console.log('onsubmit()', values);
            let extra: any = {};
            const newModel = {
              ...this.props.value,
              ...values,
              ...extra
            };
            console.log('onsubmit() newModel', newModel);
            const savedModel = await postCreateOrUpdate(newModel);
            this.props.onSubmit && this.props.onSubmit(savedModel);
          } catch (E) {
            console.log(E);
          }
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          approved: Yup.bool().required('Required')
        })}
      >
        {props => {
          const { touched, errors, isSubmitting, handleSubmit } = props;
          return (
            <Modal isOpen={true}>
              <form onSubmit={handleSubmit}>
                <ModalHeader toggle={this.cancel}>{'Edit post'}</ModalHeader>
                <ModalBody>
                  <FormGroup check>
                    <Label check>
                      <Field
                        name="approved"
                        type="checkbox"
                        className="form-check-input"
                      ></Field>
                      Approved
                    </Label>
                    {errors.approved && touched.approved && (
                      <div className="input-feedback">{errors.approved}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="postId">Id</Label>
                    <Field
                      name="postId"
                      render={(args: any) => (
                        <Input
                          {...args.field}
                          disabled={true}
                          type="text"
                          id="postId"
                        ></Input>
                      )}
                    ></Field>
                  </FormGroup>
                  <FormGroup>
                    <Label for="sourceId">Source</Label>
                    <Field
                      name="source"
                      render={(args: any) => (
                        <Input
                          value={sourceName ? sourceName : ''}
                          disabled
                          type="text"
                          id="source"
                        ></Input>
                      )}
                    ></Field>
                  </FormGroup>
                  <FormGroup>
                    <Label for="feedIds">Feeds</Label>
                    <Field
                      name="feedIds"
                      component={CustomSelect}
                      options={selectOptions}
                      isMulti={true}
                    ></Field>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                  <Button color="secondary" onClick={this.cancel}>
                    Cancel
                  </Button>
                </ModalFooter>

                {/* <DisplayFormikState {...props} /> */}
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

export default PostEdit;
