import React, { Component } from 'react';

import { connect } from 'react-redux';
import { selectCustomer } from 'store/customer/actions';
import { customerList } from 'lib/Api';
import { CustomerWithPermissions } from 'Models';
import { Link } from 'react-router-dom';

interface CustomerListProps {
  selectCustomer: typeof selectCustomer;
}

interface IState {
  customers?: CustomerWithPermissions[];
}

class CustomerList extends Component<CustomerListProps, IState> {
  state: IState = {
    customers: []
  };

  async componentDidMount() {
    const customers = await customerList();
    this.setState({ customers });
  }

  selectCustomer(customer: CustomerWithPermissions) {
    this.props.selectCustomer(customer);
  }

  render() {
    const arr = this.state.customers;
    return (
      <div>
        <ul>
          {arr &&
            arr.map(x => (
              <li key={x.id}>
                <Link
                  to=""
                  onClick={() => {
                    this.selectCustomer(x);
                  }}
                >
                  {x.name}
                </Link>{' '}
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

export default connect(null, { selectCustomer })(CustomerList);
