import React, { Component } from 'react';
import './Login.css';
import { accountConfirm } from 'lib/Api';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';

// https://localhost:44323/signup/confirmation?userId=eeac0743-2765-49e0-9fd8-708d81bbc967&code=CfDJ8FVH2mDQObBHuJ7rEmDW5N2MR2LsYg4Cij2PwCkyYTw1dK1BlYM%2FaP%2FabuOntwLxXrbUJUW4OPkU1kSL1Eq1l88OeVaehlq9fAJCghVkqu5QnarJ0Po3v6z5hNK%2BcD0FYr%2Bcw7WEao2gg5MGLFjKGzOd%2BQcIfLkXfXurSFcHqEs%2FYXIa%2BZ%2FTsUxVWNm3Yx58jM1OjJwaaXougOKyIm03WU3GGnN7VBQN8TeClArOlTk0O6GjNDAay5nUWq9HJCK%2BJg%3D%3D&page=%2Fsignup%2Fconfirmemail
interface RouteProps {
}

interface Props extends RouteComponentProps<RouteProps> {
  userHasAuthenticated?: any;
}

interface IState {
  isLoading: boolean;
  success: boolean;
}

class SignupConfirmation extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    console.log('SignupConfirmation ctor');

    this.state = {
      isLoading: true,
      success: false,
    };
  }

  componentDidMount = async () => {
    try {
      let search = new URLSearchParams(this.props.location.search);
      let userId = search.get("userId");
      let code = search.get("code");

      await accountConfirm({ userId: userId, token: code });
      this.setState({ isLoading: false, success: true });
    } catch (e) {
      this.setState({ isLoading: false, success: false });
      console.log(e);
    }
  }

  render() {
    return (
      <div className="Login">
        <h1>Sign up confirmation</h1>

        {this.state.isLoading && (
          <p>Confirming email</p>
        )}
        
        {this.state.success && (
          <Redirect to='/login'/>
        )}
      </div>
    );
  }
}

export default withRouter(SignupConfirmation);