import Post from 'components/post/Post';
import { ApiUrl, feedPostList, feedPostCreateOrUpdate } from 'lib/Api';
import * as model from 'Models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
//import TagListNew from './TagListNew';
import { RouteComponentProps } from 'react-router';
import {
  Col,
  Row
} from 'reactstrap';
import { AppState } from 'store/index';
import { fetchSources } from 'store/source/actions';
import { fetchFeeds } from 'store/feed/actions';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';

import './PostListGrid.css';
import PostEdit from 'components/post/PostEdit';

const GridItem = styled.div`
  float: left;
  padding: 8px;
  border: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

interface ISortableItemProps {
  value: model.FeedPostViewModel;
  onClick: (value: model.FeedPostViewModel) => void;
}

const SortableItem = SortableElement(({ value, onClick }: ISortableItemProps) =>
  <div className="GridItem" onClick={() => onClick(value)} >
    <Post post={value.customerPost.post} apiUrl={ApiUrl} />
  </div>
);

const GridWrapper = styled.div`
  display: block;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  position: relative;
  border-radius: 3px;
  outline: none;
`;

interface ISortableContainerProps extends Pick<ISortableItemProps, "onClick"> {
  items: model.FeedPostViewModel[];
}

const SortableList = SortableContainer(({ items, onClick }: ISortableContainerProps) => {
  return (
    <div className="GridWrapper">
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} onClick={onClick} />
      ))}
    </div>
  );
});

const SortableListContainerWrapper = styled.div`
  display: table;
  margin: 0 auto;
`;

const SortableListContainerWrapperRoot = styled.div`
  display: flex;
  align-items: center;
`;

function reorder<T>(list: Array<T>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

type TParams = { id: string };

interface IProps extends RouteComponentProps<TParams> {
  state: AppState;
  fetchSources: typeof fetchSources;
  fetchFeeds: typeof fetchFeeds;
}

interface IState {
  page?: model.PaginationInfoWrapper<model.FeedPostViewModel> | null;
  items?: model.FeedPostViewModel[];
  isLoading: boolean;
  editedItem?: model.CustomerPost | undefined;
}

class PostList extends Component<IProps, IState> {
  state: IState = {
    items: [],
    isLoading: true,
  };

  /**
   *
   */
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  getFeedId = () => {
    const feedId = this.props.match.params.id;
    return feedId;
  }

  async componentDidMount() {
    const feedId = this.getFeedId();
    const page = await feedPostList(feedId);

    //console.log("PostList componentDidMount() page=", page, page.items[0]);

    let items =
      page &&
      page.items;

    if (items) {
      items.sort((a, b) => a.sortOrder - b.sortOrder);

      console.log("componentDidMount items=", items);
    }
    // page.items.map(post => {
    //   return { ...post, feedIds: [1] };
    // });

    this.setState({
      page: page,
      items: items,
      isLoading: false
    });

    console.log('state', this.props.state);
    if (!this.props.state.source.sources) {
      this.props.fetchSources();
    }
    if (!this.props.state.feed.feeds) {
      this.props.fetchFeeds();
    }
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (!this.state.items)
      return;

    var newItems = arrayMove(this.state.items, oldIndex, newIndex);

    newItems.map((x, idx) => { return x.sortOrder = idx; });

    console.log("onSortEnd() newItems=", newItems);
    const feedId = this.getFeedId();
    feedPostCreateOrUpdate(feedId, newItems).then(() => {
      this.setState({
        items: newItems,
      });
    });
  };

  onItemClick = (item: model.FeedPostViewModel) => {
    this.setState({ editedItem: item.customerPost });
  }

  editItem = async (item: model.CustomerPost) => {
    //console.log("PostList editItem() item=", item);
  };

  onFormSubmit = async (item: model.CustomerPost) => {
    console.log("PostList onFormSubmit()");
    if (!this.state.items)
      return;

    const items = this.state.items.map(x => x);
    const idx = items.findIndex(value => value.customerPost.postId === item.postId);
    items[idx].customerPost = item;

    this.setState({ editedItem: undefined });

    // To reload the whole page use this instead.. 
    // this.setState({ isLoading: true });
    // const page = await postList();
    // this.setState({ page: page, items: page.items, isLoading: false, editedItem: undefined });
  };

  cancel() {
    this.setState({ editedItem: undefined });
  }

  renderSource(item: model.CustomerPost): string | null {
    const sources = this.props.state.source.sources;
    if (!sources) {
      return null;
    }

    const source = sources.find(val => val.id === item.sourceId);

    return source ? `#${source.hashtag}` : null;
  }

  render() {
    const feedId = this.props.match.params.id;
    const feed = this.props.state.feed.feeds && this.props.state.feed.feedById[feedId];
    return (
      <div>
        <Row className="mt-4">
          <Col>
            {/* <Button color="primary" onClick={() => this.newItem()}>New</Button> */}
            {this.state.editedItem && (
              <PostEdit
                value={this.state.editedItem}
                onSubmit={this.onFormSubmit}
                cancel={this.cancel}
                getSourceName={() =>
                  this.state.editedItem
                    ? this.renderSource(this.state.editedItem)
                    : null
                }
                getFeeds={() => this.props.state.feed.feeds || []}
              />
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h3>FeedPosts for feed {feed && feed.name}</h3>

            <SortableListContainerWrapperRoot>
              <SortableListContainerWrapper>
                {this.state.items && (
                  <SortableList axis="xy" items={this.state.items} onSortEnd={this.onSortEnd} pressDelay={200} onClick={this.onItemClick} />
                )}
              </SortableListContainerWrapper>
            </SortableListContainerWrapperRoot>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  const actions = {
    fetchSources: () => dispatch(fetchSources()),
    fetchFeeds: () => dispatch(fetchFeeds()),
  };
  return actions;
}

// ThunkDispatch<AppState, IExtraDispatchArguments, AnyAction>
export default connect(
  (state: AppState) => {
    return { state };
  },
  mapDispatchToProps
  // , (dispatch: (dispatch: any)) => ({
  //     // savePerson: (person: Person) => dispatch(savePerson(person))
  //     actions: {
  //        fetchSources: () => dispatch(fetchSources())
  //     }
  //  }
)(PostList);
