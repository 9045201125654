import React, { Component, Dispatch } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import LoaderButton from './LoaderButton';
//import FacebookButton from './FacebookButton';
import './Login.css';
import { Link, Redirect } from 'react-router-dom';
import { accountLogin } from 'lib/Api';
import { Auth } from 'lib/Auth';
import { connect } from 'react-redux';
import { AppState, AppActionTypes } from 'store';
import { login } from 'store/auth/actions';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    userHasAuthenticated: any;
  };

interface IState {
  email: string;
  password: string;
  isLoading: boolean;
}

class Login extends Component<Props, IState> {
  constructor(props: any) {
    super(props);

    console.log('Login ctor');

    this.state = {
      isLoading: false,
      email: '',
      password: '',
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleFbLogin = () => {
    this.props.userHasAuthenticated(true);
    console.log('Login.tsx handleFbLogin');
  };

  handleChange = (event: any) => {
    if (event.target.id === 'email') {
      this.setState({ email: event.target.value });
    } else if (event.target.id === 'password') {
      this.setState({ password: event.target.value });
    }
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      //await Auth.signIn(this.state.email, this.state.password);
      const tmp = await accountLogin({userName: this.state.email, password: this.state.password});
      console.log("handleSubmit() accountLogin returned=", tmp)
      await Auth.afterLogin(tmp, true);
      console.log("handleSubmit() after afterLogin")
    } catch (e) {
      console.log("Error logging in", e);
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="Login">
        <h1>Log in</h1>
        <form onSubmit={this.handleSubmit}>
          {/* <FacebookButton onLogin={this.handleFbLogin} />
          <hr /> */}
          <FormGroup>
            <Label>Email</Label>
            <Input
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              id="email"
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              id="password"
            />
          </FormGroup>
          <LoaderButton
            block
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
          />
          
          {this.props.authState.isAuthenticated && (<Redirect to="/" />)}
          
          <div className="lower">
            <p>
              <Link to="forgotpassword">Forgot your password?</Link>
            </p>
            <p>
              <Link to="signup">Sign up for new account</Link>
            </p>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    authState: state.auth,
  }
}

function mapDispatchToProps(dispatch: Dispatch<AppActionTypes>) {
  return {
    login,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
