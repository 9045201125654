import { sourceList } from 'lib/Api';
import { ThunkAction } from 'redux-thunk';
import { FETCH_SOURCES_OK, SourceState } from './types';

// TypeScript infers that this function is returning SendMessageAction
export const fetchSources = (): ThunkAction<
  Promise<void>,
  SourceState,
  {}
> => async (dispatch, getState) => {
  const sources = await sourceList();
  dispatch({
    type: FETCH_SOURCES_OK,
    payload: sources
  });
};
