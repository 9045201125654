import React, { Component } from 'react';

import { connect } from 'react-redux';
// import { selectCustomer } from 'store/customer/actions';
import { tagList } from 'lib/Api';
import * as model from 'Models';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  ListGroup,
  Button
} from 'reactstrap';

import ActionLink from 'components/ActionLink';
import FeedListNew from './FeedListNew';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

type TParams = { id: string };

interface IProps extends RouteComponentProps<TParams> { }

interface IState {
  items?: model.Feed[];
  isLoading: boolean;
  editedItem?: model.Feed | undefined;
}

class FeedList extends Component<IProps, IState> {
  state: IState = {
    items: [],
    isLoading: true
  };

  /**
   *
   */
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  async componentDidMount() {
    const items = await tagList();
    this.setState({ items: items });
  }

  renderTag = (item: model.Feed) => {
    return (
      <ListGroupItem key={item.id}>
        <ListGroupItemHeading>{item.name}</ListGroupItemHeading>
        <ListGroupItemText>
          {/* Last polled: {item.lastPolled && moment(item.lastPolled).fromNow()} <br /> */}
          {/* Enabled: {item.active ? "Yes" : "No"} <br /> */}
          <ActionLink
            onClick={() => {
              this.editItem(item);
            }}
          >
            {'Edit'}
          </ActionLink> <br />
          <Link to={`/feedposts/${item.id}`}>
            Show posts
          </Link>
          {/* <Button size="sm" onClick={() => { this.toggleEnabled(item) }} >{item.active ? "Disable" : "Enable"}</Button> */}

          {/* Connected by {item.} ({item.userEmail})<br />
                    <small>Expires {item.expiresAt && moment(item.expiresAt).fromNow()}</small> */}
        </ListGroupItemText>
      </ListGroupItem>
    );
  };

  newItem = async () => {
    this.setState({ editedItem: { id: 0, name: '' } });
  };

  editItem = async (item: model.Feed) => {
    this.setState({ editedItem: item });
  };

  onFormSubmit = async (item: model.Feed) => {
    const items = await tagList();
    this.setState({ editedItem: undefined, items: items });
  };

  cancel() {
    this.setState({ editedItem: undefined });
  }

  render() {
    const arr = this.state.items;
    return (
      <div>
        {/* <p>
                    You can have at most 30 hashtags per account. This limit is enforced per rolling 7 day period.
                </p> */}
        <div className="row mt-4">
          <div className="col-lg-6">
            <Button color="primary" onClick={() => this.newItem()}>
              Add new item
            </Button>
            {this.state.editedItem && (
              <FeedListNew
                value={this.state.editedItem}
                onSubmit={this.onFormSubmit}
                cancel={this.cancel}
              />
            )}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6">
            <h3>Feeds</h3>
            <ListGroup>{arr && arr.map(this.renderTag)}</ListGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(FeedList);
