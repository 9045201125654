import React, { Component } from 'react';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export default class ActionLink extends Component<IProps> {
  handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.onClick && this.props.onClick(e);
  };

  render() {
    return (
      <a href="#no" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}
