import React, { Component } from 'react';

// import { selectCustomer } from 'store/customer/actions';
import { sourceCreateOrUpdate } from 'lib/Api';
import * as model from 'Models';
import { FormGroup, Label, Input } from 'reactstrap';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../forms/CustomSelect';

interface IProps {
  sourceAuthList: model.SourceAuthViewModel[] | null;
  value: model.Source;
  onSubmit?: (value: model.Source) => void;
}

class SourceListNew extends Component<IProps> {
  render() {
    let sourceAuthId = 0;
    if (this.props.sourceAuthList && this.props.sourceAuthList.length > 0) {
      sourceAuthId = this.props.sourceAuthList[0].id;
    }
    let initialValues = this.props.value
      ? this.props.value
      : { hashtag: '', sourceAuthId: 0 };

    if (initialValues.sourceAuthId === 0) {
      initialValues = { ...initialValues, ...{ sourceAuthId } };
    }

    const selectOptions = this.props.sourceAuthList
      ? this.props.sourceAuthList.map(x => ({
          value: x.id,
          label: x.igUserName
        }))
      : [];
    const validOptionIds = this.props.sourceAuthList
      ? this.props.sourceAuthList.map(x => x.id)
      : [];

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            console.log('onsubmit()', values);
            let extra: any = {};
            if ((values.sourceAuthId as any) instanceof String) {
              console.log('values.sourceAuthId was string');
              extra.sourceAuthId = parseInt(values.sourceAuthId as any);
            }
            const newModel = {
              ...this.props.value,
              ...values,
              ...extra
            };
            console.log('onsubmit() newModel', newModel);
            const savedModel = await sourceCreateOrUpdate(newModel);
            this.props.onSubmit && this.props.onSubmit(savedModel);
          } catch (E) {
            console.log(E);
          }
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          hashtag: Yup.string().required('Required'),
          sourceAuthId: Yup.number()
            .oneOf(validOptionIds, 'Not a valid option')
            .required('Required')
        })}
      >
        {props => {
          const { touched, errors, isSubmitting, handleSubmit } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="hashtag">HashTag</Label>
                <Field
                  name="hashtag"
                  render={(args: any) => (
                    <Input {...args.field} type="text" id="hashtag"></Input>
                  )}
                ></Field>
                {errors.hashtag && touched.hashtag && (
                  <div className="input-feedback">{errors.hashtag}</div>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="sourceAuthId">Connected account</Label>
                {/* <Field name="sourceAuthId" component="select" >
                                        {this.props.sourceAuthList && this.props.sourceAuthList.map(x => (<option key={x.id} value={x.id}>{x.igUserName}</option>))} */}

                {/* <Field name="sourceAuthId" render={(args: any) =>
                                    <Input {...args.field} type="select" name="sourceAuthId" id="sourceAuthId" 
                                        onChange={(value) => { console.log("onChange", value); props.setFieldValue("sourceAuthId", value.target.value);}} value={values.sourceAuthId} >
                                        {this.props.sourceAuthList && this.props.sourceAuthList.map(x => (<option key={x.id} value={x.id}>{x.igUserName}</option>))}
                                    </Input>
                                }> */}

                {/* <Field name="sourceAuthId2" render={(args: any) => <ReactSelect {...args.field} options={selectOptions} /> } >
                                </Field>
 */}
                <Field
                  name="sourceAuthId"
                  component={CustomSelect}
                  options={selectOptions}
                ></Field>
                {errors.sourceAuthId && touched.sourceAuthId && (
                  <div className="input-feedback">{errors.sourceAuthId}</div>
                )}
              </FormGroup>

              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                Submit
              </button>

              {/* <DisplayFormikState {...props} /> */}
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default SourceListNew;
