import { CustomerState, CustomerActionTypes, SELECT_CUSTOMER } from './types';
import { LOGOUT } from 'store/auth/types';

const initialState: CustomerState = { customer: undefined };

export function customerReducer(
  state = initialState,
  action: CustomerActionTypes
): CustomerState {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case SELECT_CUSTOMER:
      return { ...state, customer: action.payload };
    default:
      return state;
  }
}
