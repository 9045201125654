import { useState, useCallback, useLayoutEffect } from "react";

export interface DimensionObject {
    width: number;
    height: number;
    top: number;
    left: number;
    x: number;
    y: number;
    right: number;
    bottom: number;
}

export type UseDimensionsHook = [
    (node: HTMLElement) => void,
    DimensionObject,
    HTMLElement | null
];

export interface UseDimensionsArgs {
    liveMeasure?: boolean;
}
function getDimensionObject(node: HTMLElement): DimensionObject {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        left: "x" in rect ? rect.x : 0,
        top: "y" in rect ? rect.y : 0,
        x: "x" in rect ? rect.x : 0,
        y: "y" in rect ? rect.y : 0,
        right: rect.right,
        bottom: rect.bottom
    };
}

function useDimensions({
    liveMeasure = true
}: UseDimensionsArgs = {}): UseDimensionsHook {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState<HTMLElement | null>(null);

    const ref = useCallback(node => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(node))
                );
            measure();

            if (liveMeasure) {
                window.addEventListener("resize", measure);
                //window.addEventListener("scroll", measure);

                return () => {
                    window.removeEventListener("resize", measure);
                    //window.removeEventListener("scroll", measure);
                };
            }
        }
    }, [node]);

    return [ref, dimensions as DimensionObject, node];
}

export default useDimensions;
