import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface IHomeProps {
  authenticated: boolean;
}

const Home = (props: IHomeProps) => (
  <div>
    <h3>Pages</h3>
    <Link to="/sourceauths">Connect your account</Link>
    <br />
    <Link to="/sources">Sources</Link>
    <br />
    <Link to="/feeds">Feeds</Link>
    <br />
    <Link to="/posts">Posts</Link>
    <br />
    <br />
    <br />
    <Link to="/widget">Widget test</Link>
    <br />

    <hr />

  </div>
);

export default connect()(Home);
