import { tagList } from 'lib/Api';
import { ThunkAction } from 'redux-thunk';
import { FETCH_FEEDS_OK, FeedState } from './types';

// TypeScript infers that this function is returning SendMessageAction
export const fetchFeeds = (): ThunkAction<
  Promise<void>,
  FeedState,
  {}
> => async (dispatch, getState) => {
  const Feeds = await tagList();
  dispatch({
    type: FETCH_FEEDS_OK,
    payload: Feeds
  });
};
