import React, { Component } from 'react';
import * as models from 'Models/index';

import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledImageContainer = styled.div<Pick<IState, 'isLoading'>>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
  background: black;

    ${({ isLoading }) =>
    isLoading &&
    css`
        width: 400px;
        height: 400px;
        &:before {
          content: '';
          display: block;
          width: 40px;
          height: 40px;
          background: white;
          opacity: 0.2;
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50% - 20px);
          animation: ${rotate} 3s linear infinite;
        }
      `};
`;

const StyledImage = styled.img<Pick<IState, 'isLoading'>>`
  max-width: 100%;
  max-height: 100%;
  display: block;
  pointer-events: none;
`;

interface IState {
  pageNr: number;
  isLoading: boolean;
}

interface PostProps {
  post: models.Post;
  apiUrl?: string;
}

class PostImage extends Component<PostProps, IState> {
  state: IState = {
    pageNr: 0,
    isLoading: true
  };

  getMediaUrl() {
    var nr = this.state.pageNr;
    var p = this.props.post;
    if (!p.media || p.media[nr] === undefined)
      return undefined;
    var key = p.media[nr].n;
    return `${this.props.apiUrl}Post/GetImage?key=${key}`;
  }

  imageLoaded = () => {
    this.setState({
      isLoading: false
    });
  };

  imageLoadError = () => {
    this.setState({
      isLoading: false
    });
  };

  render() {
    return (
      <StyledImageContainer isLoading={this.state.isLoading}>
        <StyledImage
          isLoading={this.state.isLoading}
          src={this.getMediaUrl()}
          alt="ig-post"
          onLoad={this.imageLoaded}
          onError={this.imageLoadError}
        />
      </StyledImageContainer>
    );
  }
}

export default PostImage;
