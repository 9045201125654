import { SELECT_CUSTOMER, CustomerActionTypes, Customer } from './types';

// TypeScript infers that this function is returning SendMessageAction
export function selectCustomer(customer: Customer): CustomerActionTypes {
  console.log('selectCustomer called', customer);
  return {
    type: SELECT_CUSTOMER,
    payload: customer
  };
}
