import { SourceState, FETCH_SOURCES_OK, SourceActionTypes } from './types';

const initialState: SourceState = { sources: undefined };

export function sourceReducer(
  state = initialState,
  action: SourceActionTypes
): SourceState {
  switch (action.type) {
    case FETCH_SOURCES_OK:
      return { ...state, sources: action.payload };
    default:
      return state;
  }
}
