import React, { useRef, FunctionComponent, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';

import Post, { dummyPosts } from '../post/Post';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import useDimensions from 'hooks/useDimensions';
import useMyScrollLock from 'hooks/useMyScrollLock';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};


// const ButtonGroup = ({ next, previous, goToSlide ...rest }) => {
//   const { carouselState: { currentSlide } } = rest;
//   return (
//     <div className="carousel-button-group"> // remember to give it position:absolute
//       <ButtonOne className={currentSlide === 0 : 'disable' : ''} onClick={() => previous()} />
//       <ButtonTwo onClick={() => next()} />
//       <ButtonThree onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </ButtonThree>
//     </div>
//   );
// };

const PostWrapper = styled.div`
  border: 2px solid black;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
`;

const PostWithStyle = styled(Post)`
  width: 100%;
  height: 100%;
`;

// Hook
function useLockBodyScroll() {
    useLayoutEffect(() => {
        // Get original body overflow
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return () => { document.body.style.overflow = originalStyle; };
    }, []); // Empty array ensures effect is only run on mount and unmount
}

type MyCarouselProps = {
    style?: React.CSSProperties;
}
const MyCarousel: React.SFC<MyCarouselProps> = props => {
    return (
        <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={false}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            renderButtonGroupOutside={false}
            arrows={true}
        >
            {dummyPosts.map((x, i) => <PostWrapper key={i} style={props.style}>
                <PostWithStyle stylePostComponent={false} apiUrl="https://app.ugcftw.win/api/" post={x} />
            </PostWrapper>
            )}
        </Carousel>
    )
}

const MyCarouselMemo = React.memo(MyCarousel);

const Widget: FunctionComponent<{}> = () => {
    const inputEl = useRef<HTMLDivElement>(null);
    const debugEl = useRef<HTMLDivElement>(null);

    //const [scrollLockEnabled, setScrollLockEnabled] = useState(false);

    const debugLog = (x: string) => {
        if (debugEl.current) {
            debugEl.current.innerText = x;
        }
    }

    const [setScrollLockEnabled] = useMyScrollLock();

    //const originalStyle = window.getComputedStyle(document.body).overflow;
    let scrollTop = window.pageYOffset;
    const onTouchStart = () => {
        if (inputEl.current) {
            // disableBodyScroll(inputEl.current);
            setScrollLockEnabled(true);
            scrollTop = window.pageYOffset;
            debugLog("onTouchStart " + scrollTop);
            console.log("div onTouchStart ");
            //inputEl.current.style.top = -scrollTop + "px";
            // document.documentElement.style.height = document.body.style.height = "100%";
            // document.documentElement.style.overflow = document.body.style.overflow = 'hidden';

            // document.ontouchmove = function (e) {
            //   e.preventDefault();
            // }
        }
    };
    const onTouchEnd = () => {
        debugLog("onTouchEnd");
        console.log("div onTouchEnd ");
        setScrollLockEnabled(false);
        (window as any).scrollLockEnabledLocal = false;
        // clearAllBodyScrollLocks();
        // document.documentElement.style.height = document.body.style.height = "";
        // document.documentElement.style.overflow = document.body.style.overflow = originalStyle;

        // document.ontouchmove = function (e) {
        //   return true;
        // }
    };

    const [ref, rect] = useDimensions();

    let ref2 = ref as any;

    let width = 400;
    if ("width" in rect) {
        width = rect.width;
        if (width >= 1024) {
            width /= 4;
        } else if (width >= 464) {
            width /= 2;
        }
        console.log("Widget rect=", rect, " width=", width);
    }

    const memoizedStyle = useMemo(() => { return { width: width, height: width } }, [width]);

    return (
        <>
            <div ref={debugEl}>
                Nothing here {width}
            </div>
            <div
                ref={inputEl}

                onTouchStart={onTouchStart}
                onTouchCancel={onTouchEnd}
                onTouchEnd={onTouchEnd}

            >
                {/* <ScrollLock isActive={scrollLockEnabled} /> */}
                {/*ref={ref2} */}
                <div ref={ref2}>
                    <MyCarouselMemo style={memoizedStyle} />
                </div>
            </div>
        </>
    );
};

export default Widget;
