export interface Owner {
  id: number;
  username: string;
  fullName: string;

  profilePicHash: string;
  profilePicUrlOrig: string;
}

export enum PostType {
  Image,
  CarouselAlbum
}

export interface Post {
  id: number;
  type: PostType;
  ownerId: number;
  owner: Owner | null;
  permaLink: string;
  content: string;
  likeCount: number;
  commentsCount: number;
  mediaUrl: string;
  mediaUrlOrig: string;
  created: Date;
  media: PostImage[];
}
export interface PostImage {
  n: string;
}

export interface Customer {
  id: number;
  name: string;
}

export interface CustomerWithPermissions extends Customer {
  permissions: PermissionViewModel[];
}

export enum Role {
  Administrator, // Kan lägga till användare och permissions
  User // Kan endast editera källor och approva poster
}

export interface PermissionViewModel {
  userId: number;
  customerId: number;
  role: Role;
}

export interface UserVm {
  ExternalId: string;
  InternalId: number | null;

  FirstName: string;
  LastName: string;
  Email: string;
}

export interface SourceAuthViewModel {
  id: number;

  accessToken: string;
  createdAt: Date | null;
  expiresAt: Date | null;

  // Data regarding the user the accesstoken belongs to
  userId: string;
  userName: string;
  userEmail: string;

  // Data regarding the facebook business Page it belongs to
  pageId: string;
  pageName: string;

  // Data regarding the instagram business account it belongs to
  igUserId: string;
  igName: string;
  igUserName: string;
}

export enum SourceType {
  Hashtag
}

export interface Source {
  id: number;

  sourceAuthId: number;

  type: SourceType;
  hashtag: string;
  active: boolean;
  lastPolled: Date | null;
}

export interface RegistrationViewModel {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface RegistrationResultViewModel {
  userAlreadyExists: boolean;
  emailConfirmed: boolean;
}

export interface RegistrationConfirmEmailViewModel {
  userId?: string | null;
  email?: string;
  token?: string | null;
}

export interface LoginViewModel {
  userName: string;
  password: string;
}

export interface AuthToken {
  token: string;
  expiresIn: number;
  expiresAt: Date;
  userId: string;
}

export interface UserViewModel {
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  emailConfirmed: boolean;
}

export interface CredentialsResponseViewModel {
  user: UserViewModel;
  authToken: AuthToken;
}

export interface Feed {
  id: number;
  name: string;
}

export interface CustomerPost {
  id: number;

  postId: number;
  post: Post;

  sourceId: number;
  // [ForeignKey("SourceId"), Required]
  // source: Source;

  feedIds: number[];

  approved: boolean;

  //sitePosts: SitePost[];
}

export interface BasePagedRequest {
  pageSize?: number;
  page?: number;
}
export interface PostListRequest extends BasePagedRequest {

}

export interface FeedPostViewModel {
  id: number;
  created: Date | string;
  sortOrder: number;
  customerPost: CustomerPost;
}

export interface PaginationInfoMetadata {
  pageSize: number;
  pageNr: number;
  totalHits: number;
  noPages: number;
}

export interface PaginationInfoWrapper<T> {
  metaData: PaginationInfoMetadata;
  items: T[];
}
