import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import * as model from 'Models';

interface IPaginationProps {
    metaData: model.PaginationInfoMetadata;
}

const Pager = (props: IPaginationProps) => {
    return (
        <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={props.metaData.pageNr === 0}>
                <PaginationLink tag={Link} first to={`?p=${0}`} >First</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={props.metaData.pageNr < 1}>
                <PaginationLink tag={Link} previous to={`?p=${props.metaData.pageNr - 1}`}  >Previous</PaginationLink>
            </PaginationItem>
            <PaginationItem active={true}>
                <PaginationLink disabled={true}>{props.metaData.pageNr + 1}</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={props.metaData.pageNr >= props.metaData.noPages - 1}>
                <PaginationLink tag={Link} next to={`?p=${props.metaData.pageNr + 1}`} >Next</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={props.metaData.pageNr >= props.metaData.noPages - 1}>
                <PaginationLink tag={Link} last to={`?p=${props.metaData.noPages - 1}`}>Last</PaginationLink>
            </PaginationItem>
        </Pagination>
    );
}

export default Pager;
