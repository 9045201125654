import ActionLink from 'components/ActionLink';
import Post from './Post';
import PostEdit from './PostEdit';
import Pager from '../common/Pager';
// import { selectCustomer } from 'store/customer/actions';
import { ApiUrl, postList } from 'lib/Api';
import * as model from 'Models';
import React, { Component } from 'react';
import { connect } from 'react-redux';
//import TagListNew from './TagListNew';
import { RouteComponentProps } from 'react-router';
import {
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row
} from 'reactstrap';
import { AppState } from 'store/index';
import { fetchSources } from 'store/source/actions';
import { fetchFeeds } from 'store/feed/actions';
import queryString from 'query-string';

type TParams = { p: string };

interface IProps extends RouteComponentProps<TParams> {
  state: AppState;
  fetchSources: typeof fetchSources;
  fetchFeeds: typeof fetchFeeds;
}

interface IState {
  page?: model.PaginationInfoWrapper<model.CustomerPost> | null;
  items?: model.CustomerPost[];
  isLoading: boolean;
  editedItem?: model.CustomerPost | undefined;
}

class PostList extends Component<IProps, IState> {
  state: IState = {
    items: [],
    isLoading: true,
  };

  /**
   *
   */
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  async componentDidMount() {
    const page = await postList();

    //console.log("PostList componentDidMount() page=", page, page.items[0]);

    const items =
      page &&
      page.items;
    // page.items.map(post => {
    //   return { ...post, feedIds: [1] };
    // });

    this.setState({
      page: page,
      items: items,
      isLoading: false
    });

    console.log('state', this.props.state);
    if (!this.props.state.source.sources) {
      this.props.fetchSources();
    }
    if (!this.props.state.feed.feeds) {
      this.props.fetchFeeds();
    }
  }

  async componentDidUpdate(prevProps: IProps) {
    // Typical usage (don't forget to compare props):

    console.log("componentDidUpdate", this.props.location.search);
    if (this.props.location.search !== prevProps.location.search) {
      let pageNr = 0;
      const tmp = queryString.parse(this.props.location.search);
      if (tmp && tmp['p']) {
        const xxx = tmp['p'];
        if (typeof xxx === 'string') {
          pageNr = Number.parseInt(xxx);
        }
      }
      this.setState({
        isLoading: true
      });
      const page = await postList({ page: pageNr });
      this.setState({
        page: page,
        items: page && page.items,
        isLoading: false
      });
    }
  }

  renderFeedList(item: model.CustomerPost) {
    if (!this.props.state.feed.feeds || !item.feedIds)
      return <></>;

    var feedById = this.props.state.feed.feedById;
    var str = item.feedIds.map(x => feedById[x] ? feedById[x].name : "-null-").join(",");
    return <p>{str}</p>;
  }

  renderSource(item: model.CustomerPost): string | null {
    const sources = this.props.state.source.sources;
    if (!sources) {
      return null;
    }

    const source = sources.find(val => val.id === item.sourceId);

    return source ? `#${source.hashtag}` : null;
  }

  renderPost = (item: model.CustomerPost) => {
    console.log("renderPost() ", item);
    return (
      <ListGroupItem key={item.post.id}>
        <ListGroupItemHeading></ListGroupItemHeading>
        <ListGroupItemText>
          {/* Last polled: {item.lastPolled && moment(item.lastPolled).fromNow()} <br /> */}
          {/* Enabled: {item.active ? "Yes" : "No"} <br /> */}
          {/* <Button size="sm" onClick={() => { this.toggleEnabled(item) }} >{item.active ? "Disable" : "Enable"}</Button> */}

          {/* Connected by {item.} ({item.userEmail})<br />
                    <small>Expires {item.expiresAt && moment(item.expiresAt).fromNow()}</small> */}
        </ListGroupItemText>
        <Row>
          <Col>
            <Post post={item.post} apiUrl={ApiUrl} />
          </Col>
          <Col>
            Source: {this.renderSource(item)} <br />
            Feeds: {this.renderFeedList(item)} <br />
            Approved: {item.approved ? "true" : "false"} <br />
            OwnerConsent: {item.approved} <br />
            <ActionLink
              onClick={() => {
                this.editItem(item);
              }}
            >
              {'Edit'}
            </ActionLink>
          </Col>
        </Row>
      </ListGroupItem>
    );
  };

  editItem = async (item: model.CustomerPost) => {
    //console.log("PostList editItem() item=", item);
    this.setState({ editedItem: item });
  };

  onFormSubmit = async (item: model.CustomerPost) => {
    console.log("PostList onFormSubmit()");
    if (!this.state.items)
      return;

    const items = this.state.items.map(x => x);
    const idx = items.findIndex(value => value.postId === item.postId);
    items[idx] = item;

    this.setState({ items: items, editedItem: undefined });

    // To reload the whole page use this instead.. 
    // this.setState({ isLoading: true });
    // const page = await postList();
    // this.setState({ page: page, items: page.items, isLoading: false, editedItem: undefined });
  };

  cancel() {
    this.setState({ editedItem: undefined });
  }

  render() {
    const arr = this.state.items;
    return (
      <div>
        {/* <p>
                    You can have at most 30 hashtags per account. This limit is enforced per rolling 7 day period.
                </p> */}
        <div className="row mt-4">
          <div className="col">
            {/* <Button color="primary" onClick={() => this.newItem()}>New</Button> */}
            {this.state.editedItem && (
              <PostEdit
                value={this.state.editedItem}
                onSubmit={this.onFormSubmit}
                cancel={this.cancel}
                getSourceName={() =>
                  this.state.editedItem
                    ? this.renderSource(this.state.editedItem)
                    : null
                }
                getFeeds={() => this.props.state.feed.feeds || []}
              />
            )}
          </div>
        </div>

        <Row>
          <Col>
            {this.state.page && this.state.page.metaData && (<Pager metaData={this.state.page.metaData} />)}
          </Col>
          <Col>
            {/* <a href={votationNominationExport(votationId)}>Export to excel</a> */}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            {' '}
            <h3>Posts 2</h3>
            <ListGroup>{arr && arr.map(this.renderPost)}</ListGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  const actions = {
    fetchSources: () => dispatch(fetchSources()),
    fetchFeeds: () => dispatch(fetchFeeds()),
  };
  return actions;
}

// ThunkDispatch<AppState, IExtraDispatchArguments, AnyAction>
export default connect(
  (state: AppState) => {
    return { state };
  },
  mapDispatchToProps
  // , (dispatch: (dispatch: any)) => ({
  //     // savePerson: (person: Person) => dispatch(savePerson(person))
  //     actions: {
  //        fetchSources: () => dispatch(fetchSources())
  //     }
  //  }
)(PostList);
