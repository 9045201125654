import React from 'react';
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './NavMenu.css';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'store/auth/actions';

import { AuthState } from 'store/auth/types';
//import { Auth } from 'aws-amplify';
import { Auth } from 'lib/Auth';
import { AppState } from 'store';

interface Props {
  auth: AuthState;
  logout?: typeof logout;
}

interface State {
  isOpen: boolean;
}

class NavMenu extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  async signOut() {
    await Auth.signOut();
  }

  render() {
    const user =
      this.props.auth && this.props.auth.data && this.props.auth.data.user;
    const given_name = user && user.firstName;
    const isAuthenticated = !!user;
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
          light
        >
          <Container fluid={true}>
            <NavbarBrand tag={Link} to="/">
              Ugcftw
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={this.state.isOpen}
              navbar
            >
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">
                    Home
                  </NavLink>
                </NavItem>
                {isAuthenticated && (
                  <>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/posts">
                        Posts
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Setup
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          <Link to="/sourceauths">Connected accounts</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/sources">Sources</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/feeds">Feeds</Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        {given_name}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem>
                          <Link to="/customers">Change customer</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to="/login">Login</Link>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={this.signOut.bind(this)}>
                          <FontAwesomeIcon icon={faSignOutAlt} />
                          Signout
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

// const mapStateToProps = (state: AppState) => ({
//   state.auth
// })

export default connect(
  (state: AppState) => {
    return { auth: state && state.auth };
  }, // ({auth: state.auth}),
  { logout }
  //dispatch => bindActionCreators(actionCreators, dispatch)
)(NavMenu);
