import { AuthState, AuthenticatinState as AuthenticationState } from './types';
import { LOGOUT, LOGIN, AuthActionTypes } from './types';

const initialState = { isAuthenticated: null, user: undefined, authState: AuthenticationState.Unknown };

// const initialState2 = {
//   isAuthenticated: true, user: {
//     attributes: {
//       email: "hugo@dokad.se",
//       email_verified: true,
//       family_name: "Hallqvist",
//       given_name: "Hugo",
//     },
//     signinUserSession: {
//       idToken: {
//         jwtToken: "eyJraWQiOiI2MGdHN0tFRlNXUXNBVk1STk5mXC9DQkpKanQ5dmVweGZtT2E1NDFWVktqTT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1MWM0MGI5OS00ZWE5LTQ4ZjMtYWY1ZS03YjQ5ZWM2NmQ1ZjIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfdldzNTM0cVdaIiwiY29nbml0bzp1c2VybmFtZSI6IjUxYzQwYjk5LTRlYTktNDhmMy1hZjVlLTdiNDllYzY2ZDVmMiIsImdpdmVuX25hbWUiOiJIdWdvIiwiYXVkIjoiN2JsdGg2N2FobjhwaGlsYzZoaHYzbWJ0Y2MiLCJldmVudF9pZCI6ImEwMTNhMmI5LTkwZTQtNGZlMi1hOTg1LTRmNGJhYTcwMzdmMiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTYzMTMwMDUyLCJleHAiOjE1NjMxMzM2NTksImlhdCI6MTU2MzEzMDA1OSwiZmFtaWx5X25hbWUiOiJIYWxscXZpc3QiLCJlbWFpbCI6Imh1Z29AZG9rYWQuc2UifQ.pttTmEmaXiG2DjsUgXnnrz0VY05MKlO20mqwqUONWD-pLt7R9RubobWmrQce4Lm7ezYZL-VirzAn66AdH7OZ9lCGg95tkV69uLnBeq2XIXGz4ZrhONIz7rDDn_SkD96538fPz72t9W-0-2DQpAkedvsYMXATiRfe-sTL2FUto1lFWOV5y7eFTEo_RdzB6zZeipCXNY1VvNxixIT2wJyAyVe_hxKI07O-77pW9vKwuj8_4ZhANJNIg93DzPgF977091Pr49_YzHkFpMzylWGdTRnhGwXY_NQsI1iLrLvof0T_G6Wdwrg8BffwtO5jR6WdPf7Ds8-G_2ln4zUIYSWzyg",
//         payload: {
//           email: "hugo@dokad.se",
//           given_name: "Hugo",
//           family_name: "Hallqvist",

//         }
//       },
//       refreshToken: {
//         token: "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.npV2qOPBf2Autypk3tjZjYZQkBM7A-ibM04nntRmxZ5aoGv7xnKLBLZyE8bD4gPyA7WmxeQwCga5il1yTCKR-RIEJ_qK3PH4x53iO4W16wYbQlvcaHEaVDKkv9eyRyRxEfZmrWFlDvl1iZI4yohQwFkPq8cIBe3xDFElsSrbA4rEw2a69Ta_ppnXZel4ukzlIM4h5QjB8QPCb79sNjnyUsXLVEBEhtSe5So4BrnqNZlCiU9fObYq9CU_pfdFLR_WZKWaLesDy3OaAcmRKx09F1Y7YG4Bhq-1TjwvwNTGQqB-vk7TpR9NU8a1TL09bi4en3vhzdLjDNsv1FnfJ-y9qA.ltLLE699BZjkqYvP.QGgErtnbIj2z41A5wZCkVtZ46_HiB1QtYa0XX4sf1Dn7TQDydwWhpnUeXDO0GE2gbovtX365nV-VMHVmCCjYsatyrpM72BMaxDwgm0CSkce92vqeGJtLvGwRETtqJS8ehUa8OqExm5a76pYGoVtbFZw6TuLQopg_S2kWuWzp3Ro2KzpE4WBnaR-AYEaaviElbJu9JJaPcsE_HMnAUKmm0p1L7QSbYc2pYiT5ulwgRtEKl8AXNMuaXnUECXskuRScz2apkkP8xgJ4zeDAjgzP5t5rYdN49fNxy6ASTzbwJXT9QsF7ExCfE2Dn0xZ5179YrXMzW2qNlizp6uqX4k7tZF1dnSuDwGQmH_QspWAJzpcLDq3O9RqTvX445mlDgmC7Ybjt2dYEUqyDb0qB3qvjkNS_mW9jfUh6IaXREPBK862YurhUgO_gbYj9v0JoGQJgCEQwW4H9FTkYJRcsL5SMNExgE3koTeAXG71TR7a6hG3T_-TK12ywOTqcbTbXtm5JqHStslfrzsSXnevUh-dap6K8lQZITLvACEohpxFCLBjG-x9ZOJmaRE9uyMkmb3Ia56cBTYrgSQ0W-R6uujWJkcqi15p9lv5DZaqozTuYDJzFg7-DevYFQB0GqDR0_ELqGYvmC5M8ZQwGL6NBIWoq-GXDls0Y06fhLsCssGWAbEVOnxl2OzNGPk36akCK2XKI-PD0wt1oSTW-L6o9Z2MGG7YZO4OBPzNrutKTEVh3Sn6cnv3y-8tBAxeqESp3kO-3x9Xejd0UPDv2n-gygk3j7SFjB4qIiGVMSRm0SjPTXK4kHlTUP0Anps1MFsPomTNGY6kFPVzFfWfo8gnwt9i3dNZhFFQjn_2f09tGA1dqPwZSurgdQkLqGubQxf89c9ntBXVEjMkHY_akPc4HgOfPpeum1mPKHiRlYNPO2C8gzxH9s8OhDj0Yk6padnnpjPIqujSmMjXN2Ckh3YzSd-hrFVcU-yc6eVJYhrqRviWOAZxpYWlwYETRtUx2frURGr0BG8jazLRGDdkB8lj8LRj0Rt8Zpyp3FQIKg3Y4O3oOvY4G5oUNtSgX87W2M5w-l0OfkHvotjZje_PgMzOBt28hazUT0HrFd7bvK84cfD2psuGZIJf0ibB-s0Y8VP52CgMEupPfcAh4bFlx4OWQJaJ6SGo8hd7xLB1A99rlSjjqpXhLB2btTr8S6q_yBRdaDjoBslUhZSPaK1vDyvSquP2jUjxdu1UjNTH6rcWaM4CyyHTlt28AKp4YWjAPOgsZJjV6f2kumhDBPXW4N9zhrn-BxwC4B22tv11PISoNtttHkh0k5WPoZAmkxjSAEvkqMu-pJvOf.Vy-4bQCbmBTpL5oDq8nXPQ",
//       }
//     }
//   }
// };

// export const actionCreators = {
//   login: (user: any) => ({ type: loginType, user: user }),
//   logout: () => ({ type: logoutType })
// };

export function authReducer(
  state = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case LOGIN:
      //return { ...state, isAuthenticated: true, user: action.user, authState: AuthenticationState.Authenticated };
      return { ...state, isAuthenticated: true, data: action.data };
    case LOGOUT:
      //return { ...state, isAuthenticated: false, user: undefined, authState: AuthenticationState.NotAuthenticated };
      return { ...state, isAuthenticated: false, data: null, };
    default:
      return state;
  }
}
