import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, RouterState } from 'react-router-redux';

import { combineReducers } from 'redux';

import { authReducer } from './auth/reducer';
import { customerReducer } from './customer/reducer';
import { sourceReducer } from './source/reducer';
import { AuthState, AuthActionTypes } from './auth/types';
import { CustomerState, CustomerActionTypes } from './customer/types';
import { SourceState, SourceActionTypes } from './source/types';
import { feedReducer } from './feed/reducer';
import { FeedState, FeedActionTypes } from './feed/types';

export const rootReducer = combineReducers<AppState>({
  auth: authReducer,
  customer: customerReducer,
  source: sourceReducer,
  feed: feedReducer,
});

// Hugo 2019-07-31, this doesn't seem to work yet
//export type AppState = ReturnType<typeof rootReducer>;
export type AppState = {
  auth: AuthState;
  customer: CustomerState;
  source: SourceState;
  feed: FeedState;
};

export type AppActionTypes = AuthActionTypes | CustomerActionTypes | SourceActionTypes | FeedActionTypes;

let store: Store<AppState> = createStore<AppState>(rootReducer);

export function configureStore(history: any, initialState: any) {
  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined') {
    if (window['devToolsExtension' as any]) {
      const w: any = window as any;
      const devtools: any = w.devToolsExtension
        ? w.devToolsExtension()
        : (f: any) => f;
      enhancers.push(devtools);
    }
  }

  store = createStore<AppState>(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware) as any, ...enhancers)
  );
  return store;
}

export function getStore() {
  return store;
}
