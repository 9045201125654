import { CredentialsResponseViewModel } from 'Models';

export interface User {
  user: any;
  signinUserSession: any;
  refreshToken: any;
}

export enum AuthenticatinState {
  Unknown,
  Authenticated,
  NotAuthenticated,
};

export interface AuthState {
  isAuthenticated: boolean | null;
  user?: User;
  authState: AuthenticatinState;
  data?: CredentialsResponseViewModel | null;
}

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

interface LoginAction {
  type: typeof LOGIN;
  // user: User;
  // userVm: UserVm;
  data: CredentialsResponseViewModel;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

export type AuthActionTypes = LoginAction | LogoutAction;
