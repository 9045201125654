import React, { Component } from 'react';

class FaceBookSdk extends Component {
  componentDidMount() {
    this.loadFacebookSDK();
  }

  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '731070053974064',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.1'
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  render() {
    return <div />;
  }
}

export default FaceBookSdk;
