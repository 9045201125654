import * as model from 'Models';
import { Store } from 'redux';
import { AppState } from 'store';
import { login, logout } from 'store/auth/actions';
import { getOrCreateUser, customerList } from './Api';
import { selectCustomer } from 'store/customer/actions';
import history from 'lib/History';

interface CurrentUserOpts {
    bypassCache: boolean;
}

interface User {
    username: string;
}


class Auth {
    user: model.CredentialsResponseViewModel | null = null;
    store?: Store<AppState>;

    /**
     *
     */
    constructor() {
        console.log("Auth ctor");
        const tmpStr = localStorage.getItem('authStr');
        if (tmpStr) {
            const tmp = JSON.parse(tmpStr) as model.CredentialsResponseViewModel;
            console.log("Auth ctor found a stored user", tmp);
            var dt = new Date(tmp.authToken.expiresAt);
            tmp.authToken.expiresAt = dt;
            if (!this.tokenIsValid(tmp)) {
                this.user = null;
                //localStorage.removeItem('authStr');
                console.log("Auth token is not valid");
            } else {
                this.user = tmp;
                console.log("Auth ctor found a stored user, user is=", this.user);
            }
        }
    }

    tokenIsValid(user: model.CredentialsResponseViewModel) {
        const tmp = Date.now() < user.authToken.expiresAt.getTime();
        console.log("tokenIsValid ", tmp, Date.now(), user.authToken.expiresAt.getTime());
        return tmp;
    }

    async getValidJwtToken() {
        if (!this.user)
            return null;

        return this.user.authToken.token;
    }

    async currentAuthenticatedUser(args?: CurrentUserOpts | null): Promise<model.CredentialsResponseViewModel | any> {
        return this.user;
    }

    async afterLogin(data: model.CredentialsResponseViewModel, redirect?: boolean): Promise<void> {
        console.log("Auth afterLogin");
        this.user = data;
        if (!this.store) {
            throw new Error("Store or history is not set");
        }
        if (data != null) {
            const dataStr = JSON.stringify(data);
            localStorage.setItem('authStr', dataStr);

            console.log('Trying to contact server for creating local user');
            const userVm = await getOrCreateUser();
            console.log('Server uservm', userVm);
            // console.log('trying to dispatch action', this.props.login);
            // this.props.login(user, userVm);
            this.store.dispatch(login(data));

            const customers = await customerList();

            console.log('got customers', customers);

            if (customers.length === 1) {
                console.log('selecting first customer!');
                this.store.dispatch(selectCustomer(customers[0]));
                if (redirect === true) {
                    history.push("/");
                }
            } else {
                if (redirect === true) {
                    history.push("/customers");
                }
            }
        }
        else {
            localStorage.removeItem('authStr');
            this.store.dispatch(logout());
        }
    }

    async signOut(): Promise<void> {
        this.user = null;
        localStorage.removeItem('authStr');
        if (this.store) {
            this.store.dispatch(logout());
        }
    }

    setStore(store: Store<AppState>) {
        this.store = store;
    }
}

const auth = new Auth();

export { auth as Auth };
