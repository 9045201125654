import { AnyAction } from 'redux';
import { Customer as C } from 'Models';

export type Customer = C;

export interface CustomerState {
  customer?: Customer;
}

export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';

interface SelectCustomerAction {
  type: typeof SELECT_CUSTOMER;
  payload: Customer;
}

export type CustomerActionTypes = SelectCustomerAction | AnyAction;
