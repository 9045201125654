import React, { Component } from 'react';

import { connect } from 'react-redux';
// import { selectCustomer } from 'store/customer/actions';
import { sourceAuthList } from 'lib/Api';
import * as model from 'Models';
import {
  Button,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  ListGroup
} from 'reactstrap';
import { Facebook } from 'lib/Facebook';
import { sourceAuthListCreateOrUpdate } from 'lib/Api';

import ActionLink from 'components/ActionLink';
import moment from 'moment';

const FB = new Facebook();

function waitForInit() {
  return new Promise((res, rej) => {
    const hasFbLoaded = () => {
      if ((window as any).FB) {
        res();
      } else {
        setTimeout(hasFbLoaded, 300);
      }
    };
    hasFbLoaded();
  });
}

interface IgAccount {
  id: string;
  name: string;
  username: string;
}

interface FbAccountItem {
  access_token: string;
  id: string;
  name: string;
  igAccount: IgAccount | null | undefined;
}

interface FbUser {
  id: string;
  name: string;
  email: string;
  expiresAt: Date;
}
interface IProps {
  //selectCustomer: typeof selectCustomer,
}

interface IState {
  items?: model.SourceAuthViewModel[];
  isLoading: boolean;
  fbUser: FbUser | null | undefined;
  accounts: FbAccountItem[] | null;
}

class SourceAuthList extends Component<IProps, IState> {
  state: IState = {
    items: [],
    isLoading: true,
    accounts: null,
    fbUser: null
  };

  async componentDidMount() {
    const items = await sourceAuthList();
    window.console.log('componentDidMount items', items);
    this.setState({ items: items });

    window.console.log('FacebookButton componentDidMount');
    await waitForInit();
    window.console.log('FacebookButton after waiting for init');
    this.setState({ isLoading: false });
    window.console.log('FacebookButton after setting state');
  }

  selectCustomer(customer: model.SourceAuthViewModel) {
    //this.props.selectCustomer(customer);
  }

  handleFbLogin = () => {};

  connectClick = async () => {
    await FB.login({
      scope:
        'public_profile,email,pages_show_list,instagram_basic,instagram_manage_comments'
    });
    const response = await FB.getLoginStatus();
    console.log('handleClick response=', response);
    if (response.status === 'connected') {
      console.log('handleClick connected');

      const authResponse = response.authResponse;

      const { data_access_expiration_time } = authResponse;

      const fbresponse = await FB.api('/me', {
        fields: 'id,name,first_name,last_name,email'
      });
      const user = {
        id: fbresponse.id,
        name: fbresponse.name,
        email: fbresponse.email,
        expiresAt: new Date(1000 * data_access_expiration_time)
      };

      const accounts = await FB.api('/me/accounts', {});
      console.log('accounts', accounts);

      const accountsData = accounts.data as FbAccountItem[];

      for (let index = 0; index < accountsData.length; index++) {
        const element = accountsData[index];

        const fbResponse = await FB.api('/' + element.id, {
          fields:
            'instagram_business_account{id,ig_id,name,username,website,followers_count,profile_picture_url}'
        });
        //console.log("api " + element.id, fbResponse);
        element.igAccount = fbResponse.instagram_business_account;
      }
      // const promises = accountsData.map()
      // await Promise.all(promises);

      const accountsDataConnectedToInstagram = accountsData.filter(
        x => x.igAccount
      );

      this.setState({
        fbUser: user,
        accounts: accountsDataConnectedToInstagram
      });
    }
  };

  selectBusinessAccount = async (item: FbAccountItem) => {
    var user = this.state.fbUser;
    if (user && item.igAccount != null) {
      try {
        const value = {
          id: 0,
          createdAt: new Date(),
          expiresAt: user.expiresAt,
          userId: user.id,
          userName: user.name,
          userEmail: user.email,
          pageId: item.id,
          pageName: item.name,
          igUserId: item.igAccount.id,
          igName: item.igAccount.name,
          igUserName: item.igAccount.username,
          accessToken: item.access_token
        };
        console.log('creating sourceAuth', value);
        await sourceAuthListCreateOrUpdate(value);

        const items = await sourceAuthList();
        this.setState({ items: items });
      } catch (e) {
        alert('Error creating/updating source' + e);
      }
    }
  };

  logout = async () => {
    await FB.logout();
    this.setState({ fbUser: null, accounts: null });
  };

  renderFbUser = (user: FbUser) => {
    return (
      <div>
        <p>
          Connected as {user.name} ({user.email})!
          <ActionLink onClick={this.logout}>Switch user</ActionLink>
        </p>
      </div>
    );
  };

  renderSourceAuthItem = (item: model.SourceAuthViewModel) => {
    return (
      <ListGroupItem key={item.id}>
        <ListGroupItemHeading>
          <a
            href={`https://www.instagram.com/${item.igUserName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.igUserName}
          </a>{' '}
          - {item.igName}{' '}
        </ListGroupItemHeading>
        <ListGroupItemText>
          Connected by {item.userName} ({item.userEmail})<br />
          <small>
            Expires {item.expiresAt && moment(item.expiresAt).fromNow()}
          </small>
        </ListGroupItemText>
      </ListGroupItem>
    );
  };

  renderFbAccountItem = (item: FbAccountItem) => {
    if (!item.igAccount || !this.state.fbUser) return <div></div>;

    return (
      <ListGroupItem key={item.id}>
        <ListGroupItemHeading>
          <a
            href={`https://www.instagram.com/${item.igAccount.username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.igAccount.username}
          </a>{' '}
          - {item.igAccount.name}{' '}
        </ListGroupItemHeading>
        <ListGroupItemText>
          Will be connected as {this.state.fbUser.name} (
          {this.state.fbUser.email})<br />
          <small>Expires {moment(this.state.fbUser.expiresAt).fromNow()}</small>
          <br />
        </ListGroupItemText>
        <ActionLink
          onClick={() => {
            this.selectBusinessAccount(item);
          }}
        >
          Connect
        </ActionLink>
      </ListGroupItem>
    );
  };

  render() {
    const arr = this.state.items;
    return (
      <div>
        <div className="row mt-4">
          <div className="col-lg-6">
            <Button color="primary" onClick={this.connectClick}>
              Connect with Facebook / Instagram
            </Button>

            {this.state.fbUser && this.renderFbUser(this.state.fbUser)}
          </div>
        </div>

        {this.state.accounts && (
          <div className="row mt-4">
            <div className="col-lg-6">
              <h3>Available accounts</h3>
              <p>
                These are the instagram business accounts connected to your
                facebook login. Choose the one you want to use!
              </p>

              <ListGroup>
                {this.state.accounts &&
                  this.state.accounts.map(this.renderFbAccountItem)}
              </ListGroup>
            </div>
          </div>
        )}

        <div className="row mt-4">
          <div className="col-lg-6">
            <h3>Connected accounts</h3>
            <ListGroup>{arr && arr.map(this.renderSourceAuthItem)}</ListGroup>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6">
            <div className="help-section">
              <h5>Getting started</h5>
              <p>You will need access to the following:</p>

              <ul>
                <li>
                  <a
                    href="https://help.instagram.com/502981923235522"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    An Instagram Business Account
                  </a>
                </li>
                <li>
                  <a
                    href="https://developers.facebook.com/docs/instagram-api/overview#pages"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    A Facebook Page connected to that account
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {})(SourceAuthList);
