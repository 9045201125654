import React, { Component } from 'react';

// import { selectCustomer } from 'store/customer/actions';
import { tagCreateOrUpdate } from 'lib/Api';
import * as model from 'Models';
import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from 'reactstrap';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

interface IProps {
  value: model.Feed;
  onSubmit?: (value: model.Feed) => void;
  cancel: () => void;
}

class FeedListNew extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
  }

  cancel() {
    this.props.cancel();
  }

  render() {
    let initialValues = this.props.value ? this.props.value : { name: '' };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            console.log('onsubmit()', values);
            let extra: any = {};
            const newModel = {
              ...this.props.value,
              ...values,
              ...extra
            };
            console.log('onsubmit() newModel', newModel);
            const savedModel = await tagCreateOrUpdate(newModel);
            this.props.onSubmit && this.props.onSubmit(savedModel);
          } catch (E) {
            console.log(E);
          }
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required')
        })}
      >
        {props => {
          const { touched, errors, isSubmitting, handleSubmit } = props;
          return (
            <Modal isOpen={true}>
              <form onSubmit={handleSubmit}>
                <ModalHeader toggle={this.cancel}>
                  {this.props.value.id ? 'Edit Feed' : 'New Feed'}
                </ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Field
                      name="name"
                      render={(args: any) => (
                        <Input {...args.field} type="text" id="name"></Input>
                      )}
                    ></Field>
                    {errors.name && touched.name && (
                      <div className="input-feedback">{errors.name}</div>
                    )}
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                  <Button color="secondary" onClick={this.cancel}>
                    Cancel
                  </Button>
                </ModalFooter>

                {/* <DisplayFormikState {...props} /> */}
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

export default FeedListNew;
